var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-8 py-4"},[_c('v-row',[_c('v-col',{staticClass:"py-4 px-4 d-flex",attrs:{"cols":"12","sm":"6"}},[(_vm.logo)?_c('v-layout',{staticClass:"text-sm-left",attrs:{"align-center":""}},[_c('v-img',{staticClass:"logoLogin",attrs:{"contain":"","src":this.serverUrl + '/images/' + _vm.logo + '?token=' + _vm.userToken}})],1):_c('v-layout',{staticClass:"text-sm-left",attrs:{"align-center":""}},[_c('v-img',{staticClass:"logoLogin",attrs:{"contain":"","src":_vm.getLogo},on:{"error":function($event){_vm.failedImage = true}}})],1)],1),_c('v-col',{staticClass:"pl-4",attrs:{"cols":"12","sm":"6"}},[_c('languageSelector',{attrs:{"addContainer":true}}),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":_vm.$t('Email'),"required":"","rules":_vm.getRules('notEmptyEmailRule'),"validate-on-blur":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":_vm.$t('Password'),"type":"password","required":"","rules":[
            (v) => !!v || _vm.$t('Password is required'),
            (v) => (v && v.length > 4) || _vm.$t('Password is too short'),
          ],"validate-on-blur":""},model:{value:(_vm.pass),callback:function ($$v) {_vm.pass=$$v},expression:"pass"}}),_c('div',{staticClass:"text-sm-right"},[_c('v-btn',{staticClass:"ml-0 mr-0",attrs:{"type":"submit","loading":_vm.loading,"color":"primary"}},[_vm._v(_vm._s(_vm.$t("Login")))])],1),(_vm.error)?_c('errorHandeling',{attrs:{"snackbarText":_vm.error,"buttons":[
            {
              isText: true,
              functionName: 'reportError',
              text: 'Report error',
            },
            {
              isText: true,
              functionName: 'clearError',
              text: 'Close',
            },
          ],"snackbarColor":"error","snackbarTimout":"-1","snackbarIcon":"mdi-alert-circle"},on:{"clearError":function($event){return _vm.$store.dispatch('resetError')}}}):_vm._e()],1),_c('p',{staticClass:"text-sm-right mt-3"},[_c('router-link',{attrs:{"to":"/forgotpassword"}},[_vm._v(" "+_vm._s(_vm.$t("Forgot password?"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }